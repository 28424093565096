<template>
    <div>
        <div class="tehnical_section">
            <h1>Техническая поддержка онлайн</h1>
            <div class="contacts_grid">
                <div class="contacts_container">
                    <h3>Онлайн-чат</h3>
                    <div>
                        Ежедневно с 9:00 до 18:00 (МСК)<br><br><br>
                        <a href="https://t.me/blokhsystems_support" target="_blank">Телеграм ↗</a>
                    </div>
                </div>
                <div class="contacts_container">
                    <h3>VK</h3>
                    <div>
                        Ежедневно с 9:00 до 18:00 (МСК)<br><br><br>
                        <a href="https://vk.com/blokhsystems" target="_blank">Вконтакте ↗</a>
                    </div>
                </div>
                <div class="contacts_container">
                    <h3>Полезные советы</h3>
                    <div>
                        Статьи о технике и ответы на частые вопросы<br><br>
                        <a href="/" target="_blank">Узнать больше ↗</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="support_section">
            <h1>Сервис и поддержка</h1>
            <div class="contacts_grid">
                <div class="contacts_container">
                    <h3>Руководства и загрузки</h3>
                    <div>
                        Инструкции, прошивки, руководства для вашей техники<br><br>
                        <a href="/" target="_blank">Скачать ↗</a>
                    </div>
                </div>
                <div class="contacts_container">
                    <h3>Сервисный центр</h3>
                    <div>
                        Найти ближайший сервисный центр<br><br><br>
                        <a href="/" target="_blank">Найти ↗</a>
                    </div>
                </div>
                <div class="contacts_container">
                    <h3>Техническая поддержка</h3>
                    <div>
                        Другие способы связи с нашими экспертами<br><br>
                        <a href="/" target="_blank">Все контакты ↗</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="form_section">
            <h1>Форма обратной связи</h1>
            <form class="feedback-form" @submit.prevent="sendFeedback">
                <h2>Обратная связь</h2>
                <div class="form-table">
                    <div class="form-row">
                        <div class="form-cell">
                            <label for="name">Имя:</label>
                        </div>
                        <div class="form-cell">
                            <label for="email">Email:</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div id="form-cell_name" class="form-cell">
                            <input type="text" id="name" name="name" aria-label="Имя" required>
                        </div>
                        <div id="form-cell_email" class="form-cell">
                            <input type="email" id="email" name="email" aria-label="Email" required>
                        </div>
                    </div>
                </div>
                <label for="message">Сообщение</label>
                <textarea id="message" name="message" rows="4" aria-label="Сообщение" required></textarea>
                <button type="submit">Отправить</button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        async sendFeedback(event) {
            const form = event.target;
            const formData = {
                name: form.name.value,
                email: form.email.value,
                message: form.message.value
            };

            try {
                const response = await fetch('/user/back.form', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                console.log('Success:', responseData);
                alert('Спасибо за ваш отзыв!');
            } catch (error) {
                console.error('Error:', error);
                alert('Произошла ошибка при отправке данных.');
            }
        }
    }
}
</script>

<style scoped>
h1 {
    margin: 0 0 10px 0;
}

.support_section {
    margin-top: 20px;
}

.contacts_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.contacts_container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: center;
    background-color: var(--dark-shade);
    border-radius: 10px;
    color: var(--light-shade);
    width: 90%;
    padding: 20px;
    margin-bottom: 10px;
    width: 284px;
}

.contacts_container p {
    padding: 0;
    margin: 0;
}

.contacts_container a {
    font-weight: bold;
    text-decoration: none;
    color: var(--dark-accent);
}

.contacts_container a:hover {
    color: var(--middle-primary);
}

.contacts_container a:visited {
    color: var(--dark-accent)
}

.contacts_container h3 {
    padding: 0;
    margin: 0 0 10px 0;
}

.feedback-form {
    background-color: var(--dark-shade);
    color: var(--light-shade);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: calc(100% - 40px);
}

.feedback-form h2 {
    margin: 0;
}

.feedback-form label {
    display: block;
    margin: 5px 0 5px;
}

.feedback-form input,
.feedback-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--light-shade);
    border-radius: 4px;
    box-sizing: border-box;
}

.feedback-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: var(--dark-accent);
    color: var(--light-shade);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.feedback-form button:hover {
    background-color: var(--middle-primary);
}

.form-table {
    display: table;
    width: 100%;
}

.form-row {
    display: table-row;
}

.form-cell {
    display: table-cell;
}

#form-cell_name {
    padding-right: 10px;
}

#form-cell_email {
    padding-left: 10px;
}

.form-cell label {
    display: block;
    margin: 0 0 5px 0;
}

.form-cell input {
    width: 100%;
}

#message {
    resize: none;
    margin-bottom: 20px;
}
</style>