<template>
	<div class="home_page">
		<ModalWindow v-if="showLoginModal" @close="showLoginModal = false">
			<LoginPage :authData="authData" @setUserData="setUserData" @close="showLoginModal = false"
				@showRegisterModal="showRegisterModal = true" @showFoggotPassModal="showFoggotPassModal = true" />
		</ModalWindow>
		<ModalWindow v-if="showRegisterModal" @close="showRegisterModal = false">
			<RegisterPage @close="showRegisterModal = false" @setUserData="setUserData" />
		</ModalWindow>
		<ModalWindow v-if="showFoggotPassModal" @close="showFoggotPassModal = false">
			<FoggotPassPage @close="showFoggotPassModal = false" />
		</ModalWindow>
		<ModalWindow v-if="showAccountModal" @close="showAccountModal = false">
			<AccountPage :userData="userData" @close="showAccountModal = false" @resetUserData="resetUserData" />
		</ModalWindow>
		<header>
			<div class="header_menu">
				<div class="header_logo_container">
				<a href="/" style="text-decoration: none">
					<img class="main_logo" src="../assets/logo.svg" alt="Main Logo" />
				</a>
				</div>
				<div class="header_menu_container">
					<ul class="menu">
						<li class="menu-item" @click="contentIndex = 0" :class="{ selected: contentIndex === 0 }">
							<a @click="contentIndex = 0">Главная</a>
						</li>
						<li class="menu-item" @click="contentIndex = 1" :class="{ selected: contentIndex === 1 }">
							<a @click="contentIndex = 1">Продукция</a>
						</li>
						<li class="menu-item" @click="contentIndex = 2" :class="{ selected: contentIndex === 2 }">
							<a @click="contentIndex = 2">О нас</a>
						</li>
						<li class="menu-item" @click="contentIndex = 3" :class="{ selected: contentIndex === 3 }">
							<a @click="contentIndex = 3">Контакты</a>
						</li>
					</ul>
				</div>
				<div v-if="userData.user_id > 0" class="user-container">
					<button class="button-account" @click="showAccountModal = true">
						Аккаунт
					</button>
				</div>
				<div v-else class="user-container">
					<button class="button-login" @click="showLoginModal = true">
						Войти
					</button>
					<button class="button-register" @click="showRegisterModal = true">
						Регистрация
					</button>
				</div>
			</div>
		</header>
		<main>
			<div class="main-container">
				<HomeMain v-if="contentIndex === 0" />
				<HomeProduction v-if="contentIndex === 1" />
				<HomeAbout v-if="contentIndex === 2" />
				<HomeContacts v-if="contentIndex === 3" />
			</div>
		</main>
		<footer class="footer">
			<div class="footer_copyright_wrap">
				<div class="footer_copyright_align">
					<p>© 2024. Все права не защищены.</p>
				</div>
			</div>
			<div class="footer_container_wrap">
				<div class="footer_language">
					<a @click="handleAction1">Россия/Русский</a>
				</div>
				<div class="footer_terms">
					<a href="/">Политика конфиденциальности</a>
					<a id="policy" href="/">Политика обработки персональных данных</a>
				</div>
				<div class="footer_sns">
					<span class="footer_sns_title">ХОТИТЕ ОСТАВАТЬСЯ В КУРСЕ НОВИНОК?</span>
					<ul class="footer_sns_list" role="list">
						<li class="footer_sns_item">
							<a href="https://t.me/blokhsystems" class="footer_sns_link" target="_blank">
								<svg class="icon" focusable="false">
									<use xlink:href="#telegram-bold" href="#telegram-bold">
										<symbol fill="none" viewBox="0 0 48 48" id="telegram-bold"
											xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd"
												d="M48 24c0 13.255-10.745 24-24 24S0 37.255 0 24 10.745 0 24 0s24 10.745 24 24zm-31.928-2.198c-2.405 1.05-4.875 2.128-7.134 3.372-1.18.864.388 1.474 1.86 2.047.233.092.464.182.682.272.18.055.365.114.551.173 1.636.521 3.46 1.103 5.048.229 2.608-1.499 5.07-3.231 7.53-4.962.806-.568 1.611-1.135 2.422-1.693l.128-.083c.69-.447 2.243-1.454 1.669-.067-1.358 1.486-2.814 2.8-4.276 4.123-.986.891-1.976 1.785-2.942 2.738-.841.683-1.715 2.058-.773 3.015 2.17 1.52 4.374 3.001 6.576 4.483.717.482 1.434.964 2.149 1.447 1.212.968 3.107.185 3.373-1.328l.356-2.087c.658-3.844 1.316-7.69 1.897-11.548.079-.605.168-1.21.258-1.815.217-1.467.435-2.936.503-4.41-.175-1.473-1.96-1.15-2.953-.818-5.106 1.942-10.16 4.029-15.194 6.152-.57.253-1.148.505-1.73.76z"
												fill="#000" style="--darkreader-inline-fill: #e8e6e3;"
												data-darkreader-inline-fill=""></path>
										</symbol>
									</use>
								</svg>
							</a>
						</li>
						<li class="footer_sns_item">
							<a href="https://www.youtube.com/channel/blokhsystems" class="footer_sns_link"
								target="_blank">
								<svg class="icon" focusable="false">
									<use xlink:href="#youtube-bold" href="#youtube-bold">
										<symbol viewBox="0 0 96 96" id="youtube-bold" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M46.415 15.003h3.174c6.211.033 28.834.27 35.138 1.965a11.79 11.79 0 018.31 8.336c1.662 6.217 1.918 18.146 1.958 21.682l.003 1.712c-.028 3.084-.247 15.579-1.96 21.994a11.793 11.793 0 01-8.311 8.336c-6.89 1.851-33.277 1.962-36.419 1.97l-3.137-.014c-7.542-.058-27.956-.36-33.894-1.956a11.794 11.794 0 01-8.311-8.336c-1.849-6.921-1.957-20.917-1.964-22.54l.01-1.364c.046-3.763.323-15.378 1.954-21.484a11.793 11.793 0 018.31-8.336c6.305-1.695 28.928-1.932 35.139-1.965zm-8.262 18.66V62.33l25.28-14.332-25.28-14.334z">
											</path>
										</symbol>
									</use>
								</svg>
							</a>
						</li>
						<li class="footer_sns_item">
							<a href="https://vk.com/blokhsystems" class="footer_sns_link" target="_blank">
								<svg class="icon" focusable="false">
									<use xlink:href="#vk-bold" href="#vk-bold">
										<symbol viewBox="0 0 96 96" id="vk-bold" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M35.053 8.002L60.28 8C82.693 8 88 13.307 88 35.72v24.56C88 82.692 82.693 88 60.28 88H35.72C13.532 88 8.109 82.799 8.003 60.947L8 35.72C8 13.53 13.201 8.108 35.053 8zm-5.769 25.347h-5.871c-1.629 0-1.952.731-1.975 1.54l-.001.098c0 1.504 1.925 9.004 8.923 18.97l.28.394c4.798 6.945 11.63 10.67 17.783 10.67l.51-.005c3.259-.076 3.668-.908 3.668-2.252v-5.25l.006-.355c.047-1.325.392-1.6 1.39-1.62l.129-.002c.846 0 2.37.452 5.815 3.783 3.952 3.952 4.63 5.758 6.831 5.758h5.815c1.694 0 2.484-.847 2.033-2.484-.49-1.579-2.293-3.84-4.648-6.53l-.264-.301c-1.355-1.58-3.388-3.331-4.009-4.178-.846-1.13-.62-1.58 0-2.597l.015-.028c.36-.584 7.065-10.126 7.776-13.409.396-1.242 0-2.145-1.75-2.145h-5.815c-1.398 0-2.079.717-2.482 1.517l-.096.21c-.36.847-3.229 7.444-7.132 11.823l-.352.347c-1.117 1.086-1.691 1.46-2.358 1.46-.396 0-.904-.452-.904-1.695V35.495c0-1.468-.451-2.146-1.693-2.146H41.76c-.903 0-1.467.678-1.467 1.355 0 1.412 2.088 1.75 2.314 5.702v8.582c0 1.863-.338 2.202-1.072 2.202-1.916 0-6.487-6.848-9.388-14.83l-.267-.752c-.564-1.638-1.129-2.259-2.597-2.259z">
											</path>
										</symbol>
									</use>
								</svg>
							</a>
						</li>
						<li class="footer_sns_item">
							<a href="https://zen.yandex.ru/user/blokhsystems" class="footer_sns_link"
								target="_blank">
								<svg class="icon" focusable="false" viewBox="0 0 40 40">
									<path
										style="stroke: none; fill-rule: nonzero; fill: rgb(0, 0, 0); fill-opacity: 1; --darkreader-inline-stroke: none; --darkreader-inline-fill: #e8e6e3;"
										d="M 39 19 C 39.25 19 39.5 18.917969 39.667969 18.75 C 39.832031 18.582031 39.917969 18.332031 39.917969 18.082031 C 39 8.5 31.417969 0.917969 21.832031 0 C 21.582031 0 21.332031 0.0820312 21.167969 0.25 C 21 0.417969 20.917969 0.667969 20.917969 0.917969 C 21 13.582031 22.75 18.832031 39 19 Z M 39 20.75 C 22.75 20.917969 21.082031 26.167969 20.917969 38.832031 C 20.917969 39.082031 21 39.332031 21.167969 39.5 C 21.332031 39.667969 21.5 39.75 21.75 39.75 C 21.75 39.75 21.832031 39.75 21.832031 39.75 C 31.417969 38.917969 39 31.332031 39.832031 21.75 C 39.832031 21.5 39.75 21.25 39.582031 21.082031 C 39.5 20.832031 39.25 20.75 39 20.75 Z M 18.082031 0 C 8.582031 0.917969 1 8.5 0.167969 18 C 0.167969 18.25 0.25 18.5 0.417969 18.667969 C 0.582031 18.832031 0.832031 18.917969 1.082031 18.917969 C 17.332031 18.75 19 13.5 19.167969 0.832031 C 19.167969 0.582031 19.082031 0.332031 18.917969 0.167969 C 18.582031 0.0820312 18.332031 0 18.082031 0 Z M 1 20.75 C 0.75 20.75 0.5 20.832031 0.332031 21 C 0.167969 21.167969 0.0820312 21.417969 0.0820312 21.667969 C 0.917969 31.25 8.5 38.832031 18.082031 39.667969 C 18.082031 39.667969 18.167969 39.667969 18.167969 39.667969 C 18.417969 39.667969 18.582031 39.582031 18.75 39.417969 C 18.917969 39.25 19 39 19 38.75 C 18.917969 26.082031 17.25 20.917969 1 20.75 Z M 1 20.75 "
										data-darkreader-inline-stroke="" data-darkreader-inline-fill=""></path>
								</svg>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
import ModalWindow from "./ModalWindow.vue";
import LoginPage from "./LoginPage.vue";
import RegisterPage from "./RegisterPage.vue";
import FoggotPassPage from "./FoggotPassPage.vue";
import HomeAbout from "./HomeComponents/HomeAbout.vue";
import HomeContacts from "./HomeComponents/HomeContacts.vue";
import HomeProduction from "./HomeComponents/HomeProduction.vue";
import HomeMain from "./HomeComponents/HomeMain.vue";
import AccountPage from "./AccountPage.vue";
import Cookies from 'js-cookie';
import axios from 'axios';

export default {
	components: {
		ModalWindow,
		LoginPage,
		RegisterPage,
		FoggotPassPage,
		AccountPage,
		HomeAbout,
		HomeContacts,
		HomeProduction,
		HomeMain,
	},
	data() {
		return {
			showLoginModal: false,
			showRegisterModal: false,
			showFoggotPassModal: false,
			showAccountModal: false,
			contentIndex: 0,
			authData: {
				alt_auth_url_en: false,
				yandex_auth_url_en: false,
				yandex_auth_url: "/",
				MANUFACTURE: "",
			},
			userData: {
				user_id: 0,
				login: "",
				device_count: 0,
				registration_date: "",
				access_token: "",
				token_type: "",
			},
		};
	},
	created() {
		if (Cookies.get('access_token')) {
			this.userData.access_token = Cookies.get('access_token');
		} else {
			this.userData.access_token = "";
		}
		if (Cookies.get('token_type')) {
			this.userData.token_type = Cookies.get('token_type');
		} else {
			this.userData.token_type = "";
		}
		if (this.userData.user_id < 1) { 
			const headers = {
				'Authorization': this.userData.token_type + " " + this.userData.access_token
			}
			let userData;
			axios
				.post("/api/user.info", {}, headers)
				.then((response) => {
					const { ID, login, device_count, registration_date, access_token, token_type } =
						response.data;
					console.log(ID, login, device_count, registration_date, access_token, token_type);
					userData = { ID, login, device_count, registration_date, access_token, token_type };
					this.setUserData(userData);
				})
				.catch((error) => {
					console.error(error);
					const statusCode = error.response.status;
					if (statusCode === 401) {
						console.log("Неавторизован или время сессии истекло!");
						this.resetUserData();
					} else {
						console.log("Произошла ошибка при авторизации!");
					}
				});
		}
		if (!this.authData.alt_auth_url_en) {
			if (!this.authData.yandex_auth_url_en) {
				fetch("/api/auth_form")
					.then((response) => response.json())
					.then((data) => {
						this.authData.yandex_auth_url = data.yandex_auth_url;
						this.authData.MANUFACTURE = data.MANUFACTURE;
						if (this.authData.yandex_auth_url !== "/") {
							this.authData.yandex_auth_url_en = true;
						}
						if (this.authData.yandex_auth_url_en) {
							this.authData.alt_auth_url_en = true;
						}
					})
					.catch((error) => {
						console.error(error);
					});
			}
		}
	},
	methods: {
		resetUserData() {
			this.userData = {
				user_id: 0,
				login: "",
				device_count: 0,
				registration_date: "",
				access_token: "",
				token_type: "",
			};
			Cookies.remove('access_token');
			Cookies.remove('token_type');
		},
		setUserData(userData) {
			this.userData.user_id = userData.ID;
			this.userData.login = userData.login;
			this.userData.device_count = userData.device_count;
			this.userData.registration_date = userData.registration_date;
			this.userData.access_token = userData.access_token;
			this.userData.token_type = userData.token_type;
			Cookies.set('access_token', this.userData.access_token);
			Cookies.set('token_type', this.userData.token_type);
			console.log('Cookies set:', this.userData);
			console.log(this.userData);
		},
	},
};
</script>

<style scoped>
body {
	font-family: Segoe UI;
	background: var(--light-shade);
	color: var(--dark-shade);
	text-align: center;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.home_page {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	min-height: calc(100vh - 60px);
	min-width: calc(100vw - 60px);
	padding: 30px;
}

.header_menu {
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header_logo_container,
.header_menu_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 30px 0 0;
	padding: 0;
}

header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0;
	padding: 0;
	width: fit-content;
}

main {
	flex: 1;
}

.main_logo {
	width: 244px;
	height: 141px;
	margin: 0;
}

.menu {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	min-height: 42px;
}

.menu-item {
	margin-right: 20px;
	cursor: pointer;
	text-decoration: none;
	color: var(--dark-shade);
	font-size: 16px;
	font-weight: bold;
	padding: 10px;
	border-radius: 5px;
	display: inline-block;
	white-space: nowrap;
}

.menu-item a {
	display: inline-block;
	white-space: nowrap;
	text-decoration: none;
	font-size: 16px;
	font-weight: bold;
	display: inline-block;
}

.menu-item:hover {
	background-color: var(--dark-accent);
	text-decoration: underline;
	cursor: pointer;
}

.menu-item.selected {
	color: var(--light-shade);
	background-color: var(--dark-shade);
}

.menu-item:last-child {
	margin-right: 0;
}

.button-login {
	background: var(--dark-shade);
	color: var(--light-shade);
	padding: 10px;
	width: 120px;
	height: 42px;
	border: none;
	border-radius: 5px;
	text-decoration: none;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.button-login:hover {
	background: var(--dark-accent);
	cursor: pointer;
}

.button-register {
	background: var(--dark-shade);
	color: var(--light-shade);
	padding: 10px;
	width: 120px;
	height: 42px;
	border: none;
	border-radius: 5px;
	text-decoration: none;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button-register:hover {
	background: var(--dark-accent);
	cursor: pointer;
}

.button-account {
	background: var(--dark-shade);
	color: var(--light-shade);
	padding: 10px;
	width: 120px;
	height: 42px;
	border: none;
	border-radius: 5px;
	text-decoration: none;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.button-account:hover {
	background: var(--dark-accent);
	cursor: pointer;
}

.user-container {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 42px;
	min-width: 260px;
}

.user-container p {
	margin: 0;
}

main {
	flex-grow: 1;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	width: 100%;
}

footer {
	background-color: var(--light-shade);
	padding: 40px 20px 0 20px;
	text-align: center;
	color: var(--dark-shade);
	position: static;
	width: 100%;
    max-width: 982px;
}

.footer_copyright_wrap {
	text-align: center;
	padding: 0;
}

.footer_copyright_align {
	display: flex;
	justify-content: left;
	align-items: center;
}

.footer_copyright_align p {
	margin: 0;
	font-size: 12px;
}

.footer_container_wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0 10px 0;
}

.footer_language {
	float: left;
	display: flex;
}

.footer_language a {
	text-decoration: none;
	font-size: 10px;
}

.footer_language a:hover {
	color: var(--middle-primary);
	cursor: pointer;
}

.footer_language a:visited {
    color: var(--dark-shade)
}

.footer_terms {
	float: left;
	display: flex;
}

.footer_terms a {
	text-decoration: none;
	font-size: 10px;
}

.footer_terms a:hover {
	text-decoration: underline;
	cursor: pointer;
}

.footer_terms a:visited {
    color: var(--dark-shade)
}

#policy {
	margin-left: 30px;
}

.footer_sns {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	float: right;
}

.footer_sns_title {
	font-weight: normal;
	font-size: 10px;
}

.footer_sns_list {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 0 0 0 20px;
	padding: 0;
}

.footer_sns_item {
	display: inline-block;
	margin-right: 10px;
}

.footer_sns_item:last-child {
	margin-right: 0;
}

.icon {
	display: inline-block;
	width: 32px;
	height: 32px;
	color: #fff;
	text-align: center;
	line-height: 32px;
	border-radius: 50%;
}

.footer_sns_link {
	text-decoration: none;
}

.footer_sns_link:hover {
	text-decoration: underline;
	cursor: pointer;
}
</style>