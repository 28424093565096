<template>
    <div class="product-card">
        <img src="../../../assets/logo_bl.svg" alt="Product WOL Image" class="product-image" />
        <div class="product-info">
            <h3>Умный выключатель Wake-On-Lan</h3>
            <p>Погрузитесь в будущее с нашим устройством, которое превращает ваш дом в настоящий умный центр управления
                сетевыми устройствами! Наш контроллер способен удаленно включать любые устройства в вашей сети с помощью
                Wake-On-Lan протокола, обеспечивая невероятное удобство и комфорт.</p>
        </div>
    </div>
</template>
