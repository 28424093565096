<template>
    <div class="product-card">
        <img src="../../../assets/logo_bl.svg" alt="Product WOL Image" class="product-image" />
        <div class="product-info">
            <h3>Умный диспенсер для напитков</h3>
            <p>Идеальные порции каждый раз! С нашим дозатором вы сможете управлять подачей напитков через голосового
                помощника или мобильное приложение, наслаждаясь точной дозировкой и стильным дизайном. Больше никаких
                разлитых напитков — только удовольствие и удобство. Поднимите свои вечеринки на новый уровень!</p>
        </div>
    </div>
</template>
