<template>
    <div class="main-container">
        <img class="main_logo" src="../assets/logo.svg" alt="Main Logo" />
        <div class="register-container">
            <form class="register-form" @submit.prevent="checkData">
                <p v-if="loginExists" style="color: red">Логин уже существует</p>
                <input type="text" id="login" name="login" placeholder="Логин" required /><br />
                <input type="text" id="first-name" name="first-name" placeholder="Имя" required /><br />
                <input type="text" id="last-name" name="last-name" placeholder="Фамилия" required /><br />
                <p v-if="passwordMismatch" style="color: red">Пароли не совпадают</p>
                <input type="password" v-model="password" id="password" name="password" placeholder="Пароль"
                    required /><br />
                <input type="password" v-model="passwordConfirm" id="password_confirm" name="password_confirm"
                    placeholder="Подтверждение пароля" required /><br />
                <div class="register-div">
                    <label for="sex">Пол:</label>
                    <select id="sex" name="sex" required>
                        <option value="male">Мужской</option>
                        <option value="female">Женский</option>
                    </select><br />
                </div>
                <div class="register-div">
                    <label for="birthday">Дата рождения:</label>
                    <input type="date" id="birthday" name="birthday" required /><br />
                </div>
                <p v-if="emailExists" style="color: red">Email уже зарегистрирован</p>
                <input type="email" id="email" name="email" placeholder="E-mail" required /><br />
                <p v-if="confidentialNotConfirmed" style="color: red">Примите условия соглашения</p>
                <div class="register-div">
                    <input type="checkbox" id="policy" name="policy" required />
                    <label for="policy"> Я принимаю условия</label><br />
                    <a href="/">соглашения о конфиденциальности.</a>
                </div>
                <button type="submit">Зарегистрироваться</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            password: "",
            passwordConfirm: "",
            passwordMismatch: false,
            loginExists: false,
            emailExists: false,
            confidentialNotConfirmed: false,
        };
    },
    methods: {
        checkData() {
            if (this.password !== this.passwordConfirm) {
                this.passwordMismatch = true;
            } else if (!this.$refs.policy.checked) {
                this.confidentialNotConfirmed = true;
            } else {
                this.passwordMismatch = false;
                this.emailExists = false;
                this.loginExists = false;
                this.confidentialNotConfirmed = false;
                this.submitForm();
            }
        },
        submitForm() {
            const formData = {
                login: document.getElementById("login").value,
                first_name: document.getElementById("first-name").value,
                last_name: document.getElementById("last-name").value,
                password: this.password,
                sex: document.getElementById("sex").value,
                birthday: document.getElementById("birthday").value,
                email: document.getElementById("email").value,
                policy: document.getElementById("policy").checked
            };
            let userData;
            axios
                .post("/api/register.user", formData)
                .then((response) => {
                    if (response.data.ID > 0) {
                        const { ID, login, device_count, registration_date, access_token, token_type } =
                            response.data;
                        console.log(ID, login, device_count, registration_date);
                        userData = { ID, login, device_count, registration_date, access_token, token_type };
                        this.$emit("setUserData", userData);
                        this.$emit("close");
                    } else {
                        if (response.data.error) {
                            alert(response.data.error)
                            if (response.data.error === "Электронная почта уже зарегистрирована!") {
                                this.emailExists = true;
                            } else if (response.data.error === "Логин уже существует!") {
                                this.loginExists = true;
                            }
                        } else alert("Ошибка при регистрации!");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    alert("Ошибка при регистрации!");
                });
        },
    },
};
</script>

<style scoped>
.main-container {
    min-width: 320px;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--light-shade);
    color: var(--dark-shade);
}

.main_logo {
    width: 90%;
    height: auto;
    margin-bottom: 20px;
}

.register-container {
    background-color: var(--light-accent);
    border-radius: 10px;
    color: var(--light-shade);
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.register-form {
    background-color: var(--light-accent);
    border-radius: 10px;
    color: var(--light-shade);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.register-form input[type="text"] {
    width: calc(100% - 20px);
    border: none;
    border-radius: 5px;
    background-color: var(--light-shade);
    color: var(--dark-shade);
    font-size: 16px;
    padding: 10px;
    margin-top: 10px;
}

.register-form input[type="password"] {
    width: calc(100% - 20px);
    border: none;
    border-radius: 5px;
    background-color: var(--light-shade);
    color: var(--dark-shade);
    font-size: 16px;
    padding: 10px;
    margin-top: 10px;
}

.register-form input[type="email"] {
    width: calc(100% - 20px);
    border: none;
    border-radius: 5px;
    background-color: var(--light-shade);
    color: var(--dark-shade);
    font-size: 16px;
    padding: 10px;
    margin-top: 10px;
}

.register-form input[type="checkbox"] {
    border: none;
    border-radius: 5px;
    background-color: var(--light-shade);
    color: var(--dark-shade);
    font-size: 16px;
    padding: 10px;
    margin-top: 10px;
}

.register-div a:visited {
    color: var(--light-shade)
}

.register-div label {
    margin-right: 10px;
    font-size: 16px;
}

.register-div select {
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--light-shade);
    color: var(--dark-shade);
    font-size: 16px;
}

.register-div input {
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--light-shade);
    color: var(--dark-shade);
    font-size: 16px;
}

.register-form button {
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 5px;
    background-color: var(--dark-shade);
    color: var(--light-shade);
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
    padding: 10px;
}

.register-form button:hover {
    background-color: var(--dark-accent);
}
</style>