<template>
    <div class="product-card">
        <img src="../../../assets/logo_bl.svg" alt="Product WOL Image" class="product-image" />
        <div class="product-info">
            <h3>HUB для устройств BLOKH SYSTEMS</h3>
            <p>Откройте для себя будущее умного дома с нашим хабом. Устройство объединяет все ваши умные
                гаджеты по протоколам Zigbee и Matter, позволяя вам контролировать освещение, безопасность, климат-контроль и многое другое – всё это
                с помощью одного удобного приложения.</p>
        </div>
    </div>
</template>
