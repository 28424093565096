<template>
    <div class="gallery">
        <img v-for="(image, index) in images" :key="index" :src="image"
            :class="{ active: index === currentImage, inactive: index !== currentImage }" alt="Gallery Image"
            class="gallery-image" />
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const images = ref([
    new URL('../../assets/logo.svg', import.meta.url).href,
    new URL('../../assets/logo_bl.svg', import.meta.url).href,
    new URL('../../assets/logo_mono.svg', import.meta.url).href
]);

const currentImage = ref(0);

let intervalId;

const startSlideshow = () => {
    intervalId = setInterval(() => {
        currentImage.value = (currentImage.value + 1) % images.value.length;
    }, 3000);
};

onMounted(() => {
    startSlideshow();
});

onUnmounted(() => {
    clearInterval(intervalId);
});
</script>

<style scoped>
.gallery {
    width: 950px;
    height: 300px;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
}

.gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

.gallery-image.active {
    opacity: 1;
    z-index: 1;
}

.gallery-image.inactive {
    opacity: 0;
    z-index: 0;
}
</style>