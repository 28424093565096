<template>
	<div>
		<div class="main-container">
			<img class="main_logo" src="../assets/logo.svg" alt="Main Logo">
			<div class="login-container">
				<form class="login-form" @submit.prevent="loginUser">
					<input type="text" v-model="login" id="login" name="login" placeholder="Логин" required><br>
					<input type="password" v-model="password" id="password" name="password" placeholder="Пароль"
						required><br>
					<button type="submit">Войти</button>
					<button type="button" @click="openRegisterModal">Регистрация</button>
				</form>
				<a @click="openFoggotPassModal" class="link-button">Забыли пароль?</a>
			</div>
			<div v-if="authData.alt_auth_url_en" class="alt-auth-container">
				<h3>Другие способы авторизации:</h3>
				<a v-if="authData.yandex_auth_url_en" :href="authData.yandex_auth_url" class="yandex-button-link">
					<img class="logo_ya" src="../assets/ya_logo.png" alt="YaIDLogo">
					Войти с Яндекс ID
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	props: {
		authData: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			login: "",
			password: "",
		};
	},
	methods: {
		openRegisterModal() {
			this.$emit('close');
			this.$emit('showRegisterModal')
		},
		openFoggotPassModal() {
			this.$emit('close');
			this.$emit('showFoggotPassModal')
		},
		loginUser() {
			const formData = {
				login: this.login,
				password: this.password,
			};
			let userData;
			axios
				.post("/api/login.user", formData)
				.then((response) => {
					const { ID, login, device_count, registration_date, access_token, token_type} =
						response.data;
					console.log(ID, login, device_count, registration_date);
					userData = { ID, login, device_count, registration_date, access_token, token_type};
					this.$emit("setUserData", userData);
					this.$emit("close");
				})
				.catch((error) => {
					console.error(error);
					const statusCode = error.response.status;
					if (statusCode === 401) {
						alert("Неверный логин или пароль!");
					} else if (statusCode === 400) {
						alert("Неверный запрос к серверу!");
					} else {
						alert("Произошла ошибка при авторизации!");
					}
				});
		}
	}
}
</script>

<style scoped>
.main-container {
	min-width: 320px;
	min-height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--light-shade);
	color: var(--dark-shade);
}

.main_logo {
	width: 90%;
	height: auto;
	margin-bottom: 20px;
}

.login-container {
	background-color: var(--light-accent);
	border-radius: 10px;
	color: var(--light-shade);
	width: 90%;
	padding: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.login-form {
	background-color: var(--light-accent);
	border-radius: 10px;
	color: var(--light-shade);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
}

.login-form input[type="text"] {
	width: calc(100% - 20px);
	border: none;
	border-radius: 5px;
	background-color: var(--light-shade);
	color: var(--dark-shade);
	font-size: 16px;
	padding: 10px;
}

.login-form input[type="password"] {
	width: calc(100% - 20px);
	border: none;
	border-radius: 5px;
	background-color: var(--light-shade);
	color: var(--dark-shade);
	font-size: 16px;
	padding: 10px;
	margin-top: 10px;
}

.login-form button {
	width: 100%;
	height: 42px;
	border: none;
	border-radius: 5px;
	background-color: var(--dark-shade);
	color: var(--light-shade);
	;
	font-size: 16px;
	cursor: pointer;
	font-weight: bold;
	margin-top: 10px;
	padding: 10px;
}

.login-form button:hover {
	background-color: var(--dark-accent);
	cursor: pointer;
}

.link-button {
	display: block;
	text-decoration: none;
	color: var(--light-shade);
	font-size: 18px;
}

.link-button:hover {
	text-decoration: underline;
	cursor: pointer;
}

.link-button a:visited {
    color: var(--light-shade)
}

.alt-auth-container {
	background-color: var(--light-accent);
	border-radius: 10px;
	color: var(--light-shade);
	width: 90%;
	padding: 10px;
}

.alt-auth-container h3 {
	font-weight: normal;
	margin: 0px;
	padding: 0px;
	margin-bottom: 15px;
}

.yandex-button-link {
	width: 100%;
	height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--dark-shade);
	color: var(--light-shade);
	border: none;
	border-radius: 5px;
	text-decoration: none;
	font-size: 16px;
	flex-direction: row;
}

.yandex-button-link:hover {
	background-color: var(--dark-accent);
	cursor: pointer;
}

.yandex-button-link img {
	width: 30px;
	height: 30px;
}
</style>