<template>
    <div class="about_container">
        <h1>О нас</h1>
        <div class="section">
            <p>Добро пожаловать на наш сайт! Мы — <strong>BLOKH SYSTEMS</strong>, начинающий разработчик и
                производитель устройств, расположенный в России.
                Наша миссия — создавать инновационные решения, которые делают ваш быт более удобным и уникальнным.</p>
        </div>

        <h1>Наша Команда</h1>
        <div class="section">
            <p>У нас небольшая команда инженеров, которая работает над разработкой и поддержкой наших продуктов.</p>
        </div>

        <h1>Наши Услуги и Продукты</h1>
        <div class="section">
            <ul>
                <li><strong>Умные устройства:</strong> Умные датчики, системы освещения и необычные решения для
                    вашего дома.</li>
                <li><strong>Интеграция и автоматизация:</strong> Решения для интеграции всех ваших умных устройств в
                    единую систему, управляемую через приложение на вашем смартфоне.</li>
                <li><strong>Консалтинг и установка:</strong> Профессиональные услуги по подбору, установке и
                    настройке оборудования для вашего умного дома.</li>
            </ul>
        </div>

        <h1>Наши Ценности</h1>
        <div class="section">
            <ul>
                <li><strong>Качество:</strong> Мы стремимся превышать ожидания наших клиентов, предоставляя
                    высококачественные и надежные устройства.</li>
                <li><strong>Инновации:</strong> Мы всегда на переднем крае технологий и стремимся внедрять новейшие
                    достижения в наши продукты.</li>
                <li><strong>Безопасность:</strong> Мы придаем первостепенное значение безопасности и
                    конфиденциальности данных наших клиентов.</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
h1 {
    margin: 0 0 10px 0;
}

strong {
    color: var(--dark-accent);
}

.about_container {
    max-width: 982px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0;
    padding: 0;
}

.section {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    min-width: calc(100% - 30px);
    min-height: 80px;
    padding: 5px 20px;
    background: var(--dark-shade);
    border-radius: 10px;
    margin: 5px 0 30px 0;
}

.section p {
    margin: 0;
    padding: 20px;
    min-width: calc(100% - 40px);
    color: var(--light-shade);
    font-size: 18px;
    text-align: justify;
}

ul {
    font-size: 18px;
    text-align: justify;
    list-style-type: none;
    margin: 0;
    background: var(--dark-shade);
    color: var(--light-shade);
    border-radius: 5px;
    padding: 10px 20px;
    min-width: calc(100% - 40px);
}

ul li {
    margin: 5px 0;
    padding: 0;
    text-align: justify;
}

ul li:first-child {
    margin-top: 0;
}

ul li:last-child {
    margin-bottom: 0;
}

</style>