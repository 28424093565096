<template>
    <div class="product-card">
        <img src="../../../assets/logo_bl.svg" alt="Product WOL Image" class="product-image" />
        <div class="product-info">
            <h3>Климатическая станция</h3>
            <p>Держите микроклимат вашего дома под контролем с климатической станцией. Устройство отслеживает
                температуру, влажность, давление и уровень CO2, предоставляя точные данные в удобном мобильном
                приложении. Следите за показателями и создавайте комфортную атмосферу в вашем доме!</p>
        </div>
    </div>
</template>
