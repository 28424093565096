<template>
    <div class="home_products_container">
        <div class="menu_container">
            <ul class="menu">
                <li class="menu-item" @click="contentIndex = 0" :class="{ selected: contentIndex === 0 }">
                    <a @click="contentIndex = 0">Все</a>
                </li>
                <li class="menu-item" @click="contentIndex = 1" :class="{ selected: contentIndex === 1 }">
                    <a @click="contentIndex = 1">Датчики</a>
                </li>
                <li class="menu-item" @click="contentIndex = 2" :class="{ selected: contentIndex === 2 }">
                    <a @click="contentIndex = 2">Умные устройства</a>
                </li>
                <li class="menu-item" @click="contentIndex = 3" :class="{ selected: contentIndex === 3 }">
                    <a @click="contentIndex = 3">Уникальные проекты</a>
                </li>
            </ul>
        </div>
        <div class="component_container">
            <component v-for="(component, index) in currentComponent" :is="component" :key="index" />
        </div>
    </div>
</template>

<script>
import ProductVue_HUB from './Products/ProductVue_HUB.vue';
import ProductVue_ClimateStation from './Products/ProductVue_ClimateStation.vue';
import ProductVue_WOL from './Products/ProductVue_WOL.vue';
import ProductVue_Barmen from './Products/ProductVue_Barmen.vue';

export default {
    components: {
        ProductVue_HUB,
        ProductVue_ClimateStation,
        ProductVue_WOL,
        ProductVue_Barmen
    },
    data() {
        return {
            contentIndex: 0,
            products: [
                'ProductVue_HUB',
                'ProductVue_ClimateStation',
                'ProductVue_WOL',
                'ProductVue_Barmen'
            ]
        };
    },
    computed: {
        currentComponent() {
            switch (this.contentIndex) {
                case 1:
                    return [this.products[1]];
                case 2:
                    return [this.products[0]];
                case 3:
                    return [this.products[2], this.products[3]];
                default:
                    return this.products;
            }
        }
    }
};
</script>

<style>
.product-card {
    position: relative;
    width: 484px;
    height: 250px;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    margin: 20px 0;
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    transition: transform 0.3s ease;
}

.product-card:hover .product-image {
    transform: scale(1.1);
}

.product-info {
    position: absolute;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: right 0.3s ease;
}

.product-card:hover .product-info {
    right: 0;
}

.product-info p {
    text-align: center;
    margin: 0;
    padding: 0 50px;
    font-size: 15px;
    line-height: 1.5;

}
</style>

<style scoped>

.home_products_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
}

.menu_container {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    min-height: 42px;
}

.menu-item {
    margin-right: 20px;
    cursor: pointer;
    text-decoration: none;
    color: var(--dark-shade);
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    white-space: nowrap;
}

.menu-item a {
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
}

.menu-item:hover {
    background-color: var(--dark-accent);
    text-decoration: underline;
    cursor: pointer;
}

.menu-item.selected {
    color: var(--light-shade);
    background-color: var(--dark-shade);
}

.menu-item:last-child {
    margin-right: 0;
}

.component_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 25px;
}
</style>