<template>
	<div class="modal">
		<div class="modal-content">
			<slot></slot>
		</div>
		<link rel="stylesheet"
			href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
		<button @click="close" class="close-button">
			<svg width="35px" height="35px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="var(--middle-primary)" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/></svg>
		</button>
	</div>
</template>
<script>
export default {
	methods: {
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style scoped>
.modal {
	z-index: 9999;
}
</style>

<style>
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-content {
	background-color: var(--light-shade);
	padding: 20px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	position: relative;
}

.close-button {
	position: relative;
	top: 0px;
	right: -20px;
	background: none;
	border: none;
	cursor: pointer;
	color: var(--middle-primary);
	font-size: 32px;
	background-color: var(--light-shade);
	border-radius: 50%;
	min-width: 48px;
	min-height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.close-button i {
	padding: 0px;
	margin: 0px;
}

.close-button:hover {
	background: var(--dark-shade);
	color: var(--dark-accent);
}
</style>