<template>
    <div class="main-container">
        <img class="main_logo" src="../assets/logo.svg" alt="Main Logo">
        <div class="foggot-container">
            <form v-if="!mainSended && !mailError" class="foggot-form" @submit.prevent="checkData">
                <input type="email" id="email" name="email" placeholder="E-mail" required><br>
                <button type="submit">Восстановить пароль</button>
            </form>
            <div class="mail-sended-container" v-else-if="mainSended">
                <p>Письмо с инструкциями по восстановлению пароля было отправлено на вашу почту. Пожалуйста, проверьте
                    свой почтовый ящик.</p>
                <button v-on:click="this.$emit('close')">Закрыть</button>
            </div>
            <div class="mail-error-container" v-else-if="mailError">
                <p>Ошибка</p>
                <button v-on:click="this.$emit('close')">Закрыть</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            mainSended: false,
            mailError: false
        };
    },
    methods: {
        checkData() {
            this.submitForm();
        },
        submitForm() {
            axios.post('/api/foggot_pass', {})
                .then(response => {
                    console.log(response.data);
                    this.mainSended = true;
                })
                .catch(error => {
                    console.error(error);
                    this.mailError = false;
                });
        }
    }
};
</script>

<style scoped>
.mail-sended-container {
    background-color: var(--light-accent);
    border-radius: 10px;
    color: var(--light-shade);
    max-width: 280px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mail-sended-container button {
    width: 100%;
    border: none;
    border-radius: 5px;
    background-color: var(--dark-shade);
    color: var(--light-shade);
    ;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
    padding: 10px;
}

.mail-sended-container button:hover {
    background-color: var(--dark-accent);
}

.mail-error-container {
    background-color: var(--light-accent);
    border-radius: 10px;
    color: var(--light-shade);
    max-width: 280px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mail-error-container button {
    width: 100%;
    border: none;
    border-radius: 5px;
    background-color: var(--dark-shade);
    color: var(--light-shade);
    ;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
    padding: 10px;
}

.mail-error-container button:hover {
    background-color: var(--dark-accent);
}

.main-container {
    min-width: 320px;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--light-shade);
    color: var(--dark-shade);
}

.main_logo {
    width: 90%;
    height: auto;
    margin-bottom: 20px;
}

.foggot-container {
    background-color: var(--light-accent);
    border-radius: 10px;
    color: var(--light-shade);
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.foggot-form {
    background-color: var(--light-accent);
    border-radius: 10px;
    color: var(--light-shade);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.foggot-form input[type="email"] {
    width: calc(100% - 20px);
    border: none;
    border-radius: 5px;
    background-color: var(--light-shade);
    color: var(--dark-shade);
    font-size: 16px;
    padding: 10px;
    margin-top: 10px;
}

.foggot-form button {
    width: 100%;
    border: none;
    border-radius: 5px;
    background-color: var(--dark-shade);
    color: var(--light-shade);
    ;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
    padding: 10px;
}

.foggot-form button:hover {
    background-color: var(--dark-accent);
}

.link-button {
    display: block;
    text-decoration: none;
    color: var(--light-shade);
    font-size: 18px;
}

.link-button:hover {
    text-decoration: underline;
}
</style>