<template>
    <div class="main-container">
        <img class="main_logo" src="../assets/logo.svg" alt="Main Logo">
        <div class="account-container">
            <div>
                <h4>Идентификатор: #{{ userData.user_id }}</h4>
                <h4>Логин: {{ userData.login }}</h4>
                <h4>Количество устройств: {{ userData.device_count }}</h4>
                <h4>Дата регистрации:</h4>
                <h4>{{ userData.registration_date }}</h4>
                <button @click="accountOut">Выйти</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        userData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        }
    },
    methods: {
        accountOut() {
            const headers = {
				'Authorization': this.userData.token_type + " " + this.userData.access_token
			}
            axios.post('/api/logout', {}, headers)
                .then(response => {
                    console.log(response.data);
                    this.$emit('resetUserData');
                    this.$emit('close');
                })
                .catch(error => {
                    console.error(error);
                    this.$emit('resetUserData');
                    this.$emit('close');
                });
        }
    }
}
</script>

<style>
.main-container {
    min-width: auto;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--light-shade);
    color: var(--dark-shade);
}

.main_logo {
    width: 90%;
    height: auto;
    margin-bottom: 20px;
}

.account-container {
    background-color: var(--light-accent);
    border-radius: 10px;
    color: var(--light-shade);
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.account-container h4 {
    margin: 5px 0 5px 0;
}

.account-container button {
    width: 100%;
    border: none;
    border-radius: 5px;
    background-color: var(--dark-shade);
    color: var(--light-shade);
    ;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
    padding: 10px;
}

.account-container button:hover {
    background-color: var(--dark-accent);
}
</style>