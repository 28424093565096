<template>
	<transition name="fade">
		<div v-if="isLoading" class="modal">
			<div class="modal-content">
				<div v-if="isLoading" class="loading-container">
					<div id="loadingSpinner">
						<div class="loading-spinner"></div>
					</div>
				</div>
			</div>
		</div>
	</transition>
	<div class="main_container">
		<HomePage />
	</div>
</template>

<script>
import HomePage from './components/HomePage.vue';

export default {
	name: 'App',
	components: {
		HomePage,
	},
	data() {
		return {
			isLoading: true
		}
	},
	mounted() {
		setTimeout(() => {
			this.isLoading = false
		}, 1000)
	}
}
</script>

<style>
:root {
	--light-shade: #E5E2E1;
	--light-accent: #81869D;
	--middle-primary: #A44955;
	--dark-accent: #BE8967;
	--dark-shade: #272B3C;
}

#app {
	height: 100%;
	min-height: 100vh;
	min-width: 100vw;
	width: auto;
	font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	background-color: var(--light-shade);
	color: var(--dark-shade);
}

#loadingSpinner {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}

.loading-spinner {
	border: 4px solid var(--light-shade);
	border-top: 4px solid var(--dark-accent);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	animation: spin 2s linear infinite;
}


@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.modal {
	z-index: 9999;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--light-shade);
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-content {
	background-color: var(--light-shade);
	padding: 20px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	position: relative;
}

.main_container {
	background-color: var(--light-shade);
	width: auto;
	height: 100%;
	min-height: 100vh;
	min-width: 100vw;
	margin: 0 auto;
	padding: 0;
	display: inline-block;
}

.loading-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading-container img {
	max-width: 100px;
	max-height: 100px;
}
</style>